import React from "react";
import NotificationManager from "react-notifications/lib/NotificationManager";
import { Link } from "react-router-dom";
import { NewClassRequest } from "../../classes/NewClassRequest";

export const columns = () => {
  return [
    {
      dataField: "expedient_number_link",
      text: "No. expediente",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
    {
      dataField: "judicial_partie",
      text: "Partido Judicial",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
    {
      dataField: "court",
      text: "Juzgado",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
    {
      dataField: "judgment_type",
      text: "Tipo de Juicio",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    },
    {
      dataField: "via",
      text: "Vía",
      sort: true,
      style: { textAlign: "center" },
      headerStyle: { textAlign: "center" }
    }
  ];
};

export const dataFake = [
  {
    expedient_number_link: (
      <Link to={'/expedient_details/AAA/3'}>AAA/3</Link>
    ),
    judicial_partie: 'PRIAN',
    court: 'Juzgado 11',
    judgment_type: 'ORAL',
    via: 'Jurídica',
    matery: 'Cívil'
  }
];

export const getExpedientsList = async (
  sizePerPage, page, setData, setTotal
) => {
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/find_expedient/${sizePerPage}?page=${page}`,
    "get",
    null,
    {}
  );

  const responseExpedients = await request.executeRequest();
  if (
    responseExpedients && responseExpedients.code === 200 &&
    responseExpedients.response && responseExpedients.response.electronicExpedients
  ) {
    const expedientsFormatted = formattedExpedients(responseExpedients.response.electronicExpedients);
    setData(expedientsFormatted);
    setTotal(responseExpedients.response.total);
  } else {
    if (
      responseExpedients && responseExpedients.code === 404 &&
      responseExpedients.response && responseExpedients.response.message
    ) {
      setData([]);
      setTotal(0);
      NotificationManager.error(
        responseExpedients.response.message,
        '',
        5000
      );
    } else {
      setData([]);
      setTotal(0);
      NotificationManager.error(
        'Ha ocurrido un error inesperado y no fue posible traer el listado de expedientes bajo tu acceso',
        '',
        5000
      );
    }
  }
};

export const getSearchOfExpedients = async (
  search, sizePerPage, page, setData, setTotal
) => {
  const request = new NewClassRequest(
    `${process.env.REACT_APP_URL_API_LARAVEL}/electronic_expedients/search/find_expedient/${search}/${sizePerPage}?page=${page}`,
    "get",
    {serach: search}
  );
  const responseExpedients = await request.executeRequest();
  if (
    responseExpedients && responseExpedients.code === 200 &&
    responseExpedients.response && responseExpedients.response.electronicExpedients
  ) {
    const expedientsFormatted = formattedExpedients(responseExpedients.response.electronicExpedients);
    setData(expedientsFormatted);
    setTotal(responseExpedients.response.total);
  } else {
    if (
      responseExpedients && responseExpedients.code === 404 &&
      responseExpedients.response && responseExpedients.response.message
    ) {
      setData([]);
      setTotal(0);
      NotificationManager.error(
        responseExpedients.response.message,
        '',
        5000
      );
    } else {
      setData([]);
      setTotal(0);
      NotificationManager.error(
        'Ha ocurrido un error inesperado y no fue posible traer el listado de expedientes bajo tu acceso',
        '',
        5000
      );
    }
  }
}

const formattedExpedients = (expedientsList) => {
  const expedients = expedientsList.map((exp, i) => ({
    ...exp,
    expedient_number_link: (
      <Link to={'/expedient_details/' + exp.id}>
        {exp.expedient_number}
      </Link>
    ),
    judicial_partie: exp.judicial_party || 'N/A',
    court: exp.court || 'N/A',
    judgment_type: exp.kind_judgment,
    via: exp.via
  }));

  return expedients;
};
